body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.slick-track {
  display: flex !important;
}

.slick-list,
.slick-track,
.slick-slide,
.slick-slide > div {
  min-height: 50px;
}

.slick-slide > div {
  display: flex;
}

.slick-slide > div {
  overflow: visible;
}
